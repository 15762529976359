import React from 'react'

import { NoStylesExternalLink } from 'components/Links'
import { PrimaryButton, TextButton } from 'components/Buttons'

import { Box } from '@material-ui/core'
import { PLATFORM_ACTION_LINKS } from '../consts'

/* eslint-disable react/no-array-index-key */
const AccountButtons = () => (
  <Box display="grid" gridAutoFlow="column" gridColumnGap="8px">
    {PLATFORM_ACTION_LINKS.map(({ buttonLabel, options }, index) => {
      const ButtonComponent = index === 0 ? TextButton : PrimaryButton

      return (
        <NoStylesExternalLink key={index} href={options[0].href}>
          <ButtonComponent noPadding>{buttonLabel}</ButtonComponent>
        </NoStylesExternalLink>
      )
    })}
  </Box>
)

export default AccountButtons
