import { Box } from '@material-ui/core'
import React from 'react'

import { AccountButtons, GenericTextDropdown } from './index'
import Separator from './Separator'

export const UserManageLinks = ({ headerDropdowns }) => (
  <Box display="grid" gridGap="24px" gridAutoFlow="column" alignItems="center">
    {(headerDropdowns || []).map(({ title, items }) => (
      <GenericTextDropdown key={title} mainBarTitle={title} options={items} />
    ))}
    <Separator />
    <AccountButtons />
  </Box>
)
